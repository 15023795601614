import React, { useEffect, useState } from "react";
import CitySelect from "../citySelect/CitySelect";
import DateSelect from "../dateSelect/DateSelect";
import TimeSelect from "../timeSelect/TimeSelect";
import moment from "moment";

import "./style.scss";

const startTime = "00:00";
const interval = 30;
const numObjects = 48;
const now = moment().format("YYYY-MM-DD HH:mm");
const tomorrow = moment().add(1, "d").format("YYYY-MM-DD HH:mm");

const passengersOptions = new Array(130).fill(null).map((_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
}));

const CalculatorMapForm = ({
  pathData,
  selectDateData,
  selectTimeData,
  selectPersonsData,
  selectFromData,
  selectToData,
  dateValid,
  fromValid,
  toValid,
  timeValid,
  personsValid,
  dateFromToSend,
  lang,
  CitiesArray,
  dateArray,
  timeArray,
  personsArray,
  formData,
  firstDateData,
  index,
  updateFirstDateData,
}) => {
  const [fromData, setFromData] = useState("");
  const [toData, setToData] = useState("");
  const [cityFromData, setCityFromData] = useState("");
  const [cityToData, setCityToData] = useState("");
  const [timeData, setTimeData] = useState("");
  const [personsData, setPersonsData] = useState("");
  const [choices, setChoices] = useState("");
  const [resetTimeData, setResetTimeData] = useState(false);

  const isSelectENLang = lang === "en";

  const timeOptions = new Array(numObjects).fill().map((_, i) => {
    const minutes = interval * i;
    const hours = Math.floor(minutes / 60);
    const formattedMinutes = (minutes % 60).toString().padStart(2, "0");
    const time = `${(parseInt(startTime.split(":")[0]) + hours)
      .toString()
      .padStart(2, "0")}:${formattedMinutes}`;
    const optionTime = new Date();
    optionTime.setHours(parseInt(startTime.split(":")[0]) + hours);
    optionTime.setMinutes(minutes % 60);
    const isToday = choices
      ? moment(moment(choices).format("YYYY-MM-DD")).isSame(
          moment(optionTime).format("YYYY-MM-DD")
        )
      : false;
    return {
      value: time,
      label: time,
      isDisabled: isToday
        ? !moment(choices).isSameOrBefore(
            moment(optionTime).format("YYYY-MM-DD HH:mm")
          )
        : false,
    };
  });
  const updateFromData = (value, name) => {
    setFromData(value);
    selectFromData(value, name);
  };
  const updateDateFromForSend = (value) => {
    setCityFromData(value);
  };
  const updateToData = (value, name) => {
    setToData(value);
    selectToData(value, name);
  };
  const updateDateToForSend = (value) => {
    setCityToData(value);
  };
  const updateDateData = (value) => {
    selectDateData(value);
    dateFromToSend(value);
    setChoices(
      moment(value)
        .add(new Date().getHours(), "h")
        .add(new Date().getMinutes(), "m")
        .format("YYYY-MM-DD HH:mm")
    );
    if (timeData) {
      setResetTimeData(true);
    }
  };
  const updateTimeData = (value) => {
    selectTimeData(value);
    setTimeData(value.value);
    if (resetTimeData) {
      setResetTimeData(false);
    }
  };
  const updatePersonsData = (value) => {
    selectPersonsData(value);
    setPersonsData(value.value);
  };

  useEffect(() => {
    pathData([fromData, toData]);
    CitiesArray([cityFromData, cityToData]);
    dateArray([moment(choices).format("YYYY-MM-DD")]);
    timeArray([timeData]);
    personsArray([personsData]);
  }, [fromData, toData, cityFromData, cityToData, timeData, personsData]);

  return (
    <div className="form-item">
      <div className="form-item-input city">
        <CitySelect
          direction={"from"}
          label={isSelectENLang ? "Departure point" : "Откуда"}
          updateFromData={updateFromData}
          fromValid={fromValid}
          updateDateFromForSend={updateDateFromForSend}
          selectedToData={formData?.toData}
          toData={toData}
        />
      </div>
      <div className="form-item-input city">
        <CitySelect
          direction={"to"}
          label={isSelectENLang ? "Destination" : "Куда"}
          updateToData={updateToData}
          toValid={toValid}
          updateDateToForSend={updateDateToForSend}
          selectedFromData={formData?.fromData}
          fromData={fromData}
        />
      </div>
      <div className="form-item-input date">
        <DateSelect
          label={isSelectENLang ? "Date" : "Дата"}
          updateDateData={updateDateData}
          dateValid={dateValid}
          selectedDateData={formData?.date}
          isSelectENLang={isSelectENLang}
          firstDateData={firstDateData}
          index={index}
          updateFirstDateData={updateFirstDateData}
        />
      </div>
      <div className="form-item-input time">
        <TimeSelect
          label={isSelectENLang ? "Time" : "Время"}
          options={timeOptions}
          updateTimeData={updateTimeData}
          timeValid={timeValid}
          resetTimeData={resetTimeData}
        />
      </div>
      <div className="form-item-input person">
        <TimeSelect
          label={isSelectENLang ? "Passengers" : "Пассажиры"}
          options={passengersOptions}
          updateTimeData={updatePersonsData}
          personsValid={personsValid}
          selectedPersons={formData?.persons}
        />
      </div>
    </div>
  );
};

export default CalculatorMapForm;
