import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./style.scss";

import logo from "../../../src/assets/images/logo.svg";

const options = [
  { value: "ru", label: "RU", route: "/" },
  { value: "en", label: "EN", route: "/en" },
];

const Header = ({ lang }) => {
  const [selectedValue, setSelectedValue] = useState(
    lang === "en" ? options[1] : options[0]
  );
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isSelectENLang = lang === "en";
  const refSelect = useRef();
  const changeLanguage = (language) => {
    setSelectedValue(language);
    navigate(language.route);
  };
  useEffect(() => {
    window.addEventListener("click", (event) => {
      if (String(event?.target?.className).includes("container")) {
        refSelect.current?.onMenuClose();
      }
    });
  }, []);
  return (
    <div className="header" id="header">
      <div className="header container">
        <Link to="header" className="header-logo" smooth={true} duration={500}>
          <img src={logo} alt="logo" />
        </Link>
        <nav className={`header-nav ${isOpen ? "open" : ""}`}>
          <div>
            <Link
              to="park"
              smooth={true}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              {isSelectENLang ? "Aircraft fleet" : "Флот"}
            </Link>
          </div>
          <div>
            <Link
              to="about"
              smooth={true}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              {isSelectENLang ? "About us" : "О компании"}
            </Link>
          </div>
          <div>
            <Link
              to="contacts"
              smooth={true}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              {isSelectENLang ? "Contacts" : "Контакты"}
            </Link>
          </div>
          <Select className="hidden__mobile"
            value={selectedValue}
            onChange={changeLanguage}
            options={options}
            placeholder={false}
            isSearchable={false}
            ref={refSelect}
          />
        </nav>
        <div className="header-flex hidden__desktop">
          <Select
            value={selectedValue}
            onChange={changeLanguage}
            options={options}
            placeholder={false}
            isSearchable={false}
            ref={refSelect}
          />
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.41 12L19.71 5.71C19.8983 5.5217 20.0041 5.2663 20.0041 5C20.0041 4.7337 19.8983 4.47831 19.71 4.29C19.5217 4.1017 19.2663 3.99591 19 3.99591C18.7337 3.99591 18.4783 4.1017 18.29 4.29L12 10.59L5.71 4.29C5.5217 4.1017 5.2663 3.99591 5 3.99591C4.7337 3.99591 4.4783 4.1017 4.29 4.29C4.1017 4.47831 3.99591 4.7337 3.99591 5C3.99591 5.2663 4.1017 5.5217 4.29 5.71L10.59 12L4.29 18.29C4.19627 18.383 4.12188 18.4936 4.07111 18.6154C4.02034 18.7373 3.9942 18.868 3.9942 19C3.9942 19.132 4.02034 19.2627 4.07111 19.3846C4.12188 19.5064 4.19627 19.617 4.29 19.71C4.38296 19.8037 4.49356 19.8781 4.61542 19.9289C4.73728 19.9797 4.86799 20.0058 5 20.0058C5.13201 20.0058 5.26272 19.9797 5.38458 19.9289C5.50644 19.8781 5.61704 19.8037 5.71 19.71L12 13.41L18.29 19.71C18.383 19.8037 18.4936 19.8781 18.6154 19.9289C18.7373 19.9797 18.868 20.0058 19 20.0058C19.132 20.0058 19.2627 19.9797 19.3846 19.9289C19.5064 19.8781 19.617 19.8037 19.71 19.71C19.8037 19.617 19.8781 19.5064 19.9289 19.3846C19.9797 19.2627 20.0058 19.132 20.0058 19C20.0058 18.868 19.9797 18.7373 19.9289 18.6154C19.8781 18.4936 19.8037 18.383 19.71 18.29L13.41 12Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2H17C17.2652 2 17.5196 1.89464 17.7071 1.70711C17.8946 1.51957 18 1.26522 18 1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM17 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H17C17.2652 12 17.5196 11.8946 17.7071 11.7071C17.8946 11.5196 18 11.2652 18 11C18 10.7348 17.8946 10.4804 17.7071 10.2929C17.5196 10.1054 17.2652 10 17 10ZM17 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H17C17.2652 7 17.5196 6.89464 17.7071 6.70711C17.8946 6.51957 18 6.26522 18 6C18 5.73478 17.8946 5.48043 17.7071 5.29289C17.5196 5.10536 17.2652 5 17 5Z"
                  fill="white"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
