import React, { useEffect, useState } from "react";
import Map from "../map/Map";
import CalculatorMapForm from "../calculatorForm/calculatorMapForm/CalculatorMapForm";
import Comment from "../calculatorForm/comment/Comment";
import MainButton from "../buttons/main-button/MainButton";
import PlanesTypes from "../calculatorForm/planesTypes/PlanesTypes";
import FeedBackModal from "../modals/FeedBackModal";
import { Link } from "react-scroll";

import "./styles.scss";

const MapForm = ({ lang }) => {
  const isSelectENLang = lang === "en";

  const [paths, setPaths] = useState([]);
  const [pathLength, setPathLength] = useState(1);
  const [showPlanes, setShowPlanes] = useState(false);
  const [openFeedBackModal, setOpenFeedBackModal] = useState(false);

  const [forms, setForms] = useState([
    {
      id: 0,
      fromData: null,
      toData: null,
      date: null,
      persons: null,
    },
  ]);

  const [fromData, setFromData] = useState("");
  const [toData, setToData] = useState("");
  const [dateData, setDateData] = useState("");
  const [timeData, setTimeData] = useState("");
  const [personsData, setPersonsData] = useState(0);

  const [firstFromData, setFirstFromData] = useState("");
  const [firstToData, setFirstToData] = useState("");
  const [firstDateData, setFirstDateData] = useState("");
  const [firstTimeData, setFirstTimeData] = useState("");
  const [firstPersonsData, setFirstPersonsData] = useState("");

  const [dateValid, setDateValid] = useState(true);
  const [fromValid, setFromValid] = useState(true);
  const [toValid, setToValid] = useState(true);
  const [timeValid, setTimeValid] = useState(true);
  const [personsValid, setPersonsValid] = useState(true);
  const [dataForSend, setDataForSend] = useState({});
  const [comment, setComment] = useState("");
  const [datePickerFromSend, setDatePickerFromSend] = useState("");
  const [cities, setCities] = useState([]);
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const [persons, setPersons] = useState([]);
  const [airplaneType, setAirplaneType] = useState("");

  const handlePath = (newPath, index) => {
    const newPaths = [...paths];
    newPaths[index] = newPath;
    setPaths(newPaths);
  };
  const handleCity = (newCity, index) => {
    const newCityPaths = [...cities];
    newCityPaths[index] = newCity;
    setCities(newCityPaths);
  };
  const handleDate = (newDate, index) => {
    const newDateInfo = [...dates];
    newDateInfo[index] = newDate;
    setDates(newDateInfo);
  };
  const handleTime = (newTime, index) => {
    const newTimeInfo = [...times];
    newTimeInfo[index] = newTime;
    setTimes(newTimeInfo);
  };
  const handlePerson = (newPerson, index) => {
    const newPersonInfo = [...persons];
    newPersonInfo[index] = newPerson;
    setPersons(newPersonInfo);
  };
  const addNewForm = () => {
    if (fromData && toData && dateData && timeData && personsData) {
      setPathLength(pathLength + 1);
      if (forms.length === 1) {
        forms.push({
          id: pathLength,
          fromData: { name: firstFromData.name, coords: firstFromData.coords },
          toData: { name: firstToData.name, coords: firstToData.coords },
          date: firstDateData,
          persons: personsData,
        });
      } else {
        forms.push({
          id: pathLength,
          fromData: null,
          toData: null,
          date: null,
          persons: null,
        });
      }
    } else {
      if (!dateData) {
        setDateValid(false);
      }
      if (!fromData) {
        setFromValid(false);
      }
      if (!toData) {
        setToValid(false);
      }
      if (!timeData) {
        setTimeValid(false);
      }
      if (!personsData) {
        setPersonsValid(false);
      }
    }
  };

  const selectFromData = (val, name) => {
    setFromData(val);
    if (pathLength === 1) {
      setFirstFromData({ name, coords: val });
    }
    if (val) {
      setFromValid(true);
    } else {
      setFromValid(false);
      setShowPlanes(false);
    }
  };
  const selectToData = (val, name) => {
    setToData(val);
    if (pathLength === 1) {
      setFirstToData({ name, coords: val });
    }
    if (val) {
      setToValid(true);
    } else {
      setToValid(false);
      setShowPlanes(false);
    }
  };
  const selectDateData = (val) => {
    setDateData(val);
    if (pathLength === 1) {
      setFirstDateData(val);
    }
    setDateValid(true);
  };

  const selectDatePickerFromSend = (val) => {
    setDatePickerFromSend(val);
  };
  const updateFirstDateData = (val) => {
    setFirstDateData(val);
  };
  const selectTimeData = (val) => {
    setTimeData(val);
    setTimeValid(true);
    if (pathLength === 1) {
      setFirstTimeData(val);
    }
  };
  const selectPersonsData = (val) => {
    setPersonsData(val);
    setPersonsValid(true);
    if (pathLength === 1) {
      setFirstPersonsData(val);
    }
  };
  const handleChildValueChange = (childValue) => {
    setComment(childValue);
  };
  const handlerTypeAirplane = (value) => {
    setAirplaneType(value);
  };
  const openModelToSena = () => {
    setOpenFeedBackModal(true);
    setDataForSend({ cities, times, persons, dates, type: airplaneType });
  };
  const openPlanes = () => {
    if (fromData && toData && dateData && timeData && personsData) {
      setShowPlanes(true);
    } else {
      if (!dateData) {
        setDateValid(false);
      }
      if (!fromData) {
        setFromValid(false);
      }
      if (!toData) {
        setToValid(false);
      }
      if (!timeData) {
        setTimeValid(false);
      }
      if (!personsData) {
        setPersonsValid(false);
      }
    }
  };
  const deleteForm = (id, index) => {
    setForms(forms.filter((form) => form.id !== id));
    setPaths(paths.filter((_, i) => i !== index));
    setCities(cities.filter((_, i) => i !== index));
    setDates(dates.filter((_, i) => i !== index));
    setPersons(persons.filter((_, i) => i !== index));
    setTimes(times.filter((_, i) => i !== index));
    if (forms.length === 2) {
      setFromData(firstFromData.coords);
      setToData(firstToData.coords);
      setTimeData(firstTimeData);
      setPersonsData(firstPersonsData);
    }
  };
  return (
    <div className="form">
      {forms.map((form, index) => {
        return (
          <div key={form.id}>
            {form.id !== 0 && (
              <div
                className="form-delete-flex"
                onClick={() => deleteForm(form.id, index)}
              >
                <div className="form-delete"></div>
                <span className="form-delete-text">
                  {isSelectENLang ? "Delete flight" : "Удалить перелет"}
                </span>
              </div>
            )}
            <CalculatorMapForm
              index={index}
              pathData={(newPath) => handlePath(newPath, index)}
              selectFromData={selectFromData}
              selectToData={selectToData}
              selectDateData={selectDateData}
              selectTimeData={selectTimeData}
              selectPersonsData={selectPersonsData}
              dateValid={dateValid}
              fromValid={fromValid}
              toValid={toValid}
              timeValid={timeValid}
              personsValid={personsValid}
              dateFromToSend={selectDatePickerFromSend}
              lang={lang}
              CitiesArray={(newCities) => handleCity(newCities, index)}
              dateArray={(newDate) => handleDate(newDate, index)}
              timeArray={(newTime) => handleTime(newTime, index)}
              personsArray={(newPerson) => handlePerson(newPerson, index)}
              formData={form}
              firstDateData={firstDateData}
              updateFirstDateData={updateFirstDateData}
            />
          </div>
        );
      })}
      <div className="form-add">
        <div className="form-add-flex" onClick={addNewForm}>
          <svg
            className="form-add-icon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
              fill="#C39D64"
            />
          </svg>
          <span>
            {isSelectENLang ? "Add another flight" : "Добавить еще перелет"}
          </span>
        </div>
        <Link
          to="planes"
          smooth={true}
          offset={showPlanes ? -300 : -500}
          duration={500}
        >
          <MainButton onPress={openPlanes}>
            {isSelectENLang ? "Select an aircraft" : "Выберите самолет"}
          </MainButton>
        </Link>
      </div>
      <Map id="title" pathArr={paths} />
      <div className={`planes-comment-wrapper ${showPlanes ? "opened" : ""}`}>
        <PlanesTypes
          id="planes"
          count={personsData}
          lang={lang}
          type={handlerTypeAirplane}
        />
        <Comment
          labelText="Комментарий"
          placeholderText="Введите текст"
          onValueChange={handleChildValueChange}
          lang={lang}
        />
        <MainButton onPress={() => openModelToSena()}>
          {isSelectENLang ? "Continue" : "Далее"}
        </MainButton>
      </div>
      {openFeedBackModal && (
        <FeedBackModal
          isOpen={openFeedBackModal}
          onClose={() => setOpenFeedBackModal(false)}
          dataForSend={{ ...dataForSend, comment }}
          lang={lang}
        />
      )}
    </div>
  );
};

export default MapForm;
