//Изображение самолета в слайдере
import plane1 from "../../assets/images/Plane 1.png"
import plane2 from "../../assets/images/Plane 2.png"
import plane3 from "../../assets/images/Plane 3.png"
import plane4 from "../../assets/images/Plane 4.png"
import plane5 from "../../assets/images/Plane 5.png"
import plane6 from "../../assets/images/Plane 6.png"
import plane7 from "../../assets/images/Plane 7.png"


// изобрадение схемы внутри самолета
import schemePlane1 from "../../assets/images/scheme-plane1.svg"
import schemePlane2 from "../../assets/images/scheme-plane2.svg"
import schemePlane3 from "../../assets/images/scheme-plane3.svg"
import schemePlane4 from "../../assets/images/scheme-plane4.svg"
import schemePlane5 from "../../assets/images/scheme-plane5.svg"
import schemePlane6 from "../../assets/images/scheme-plane6.svg"
import schemePlane7 from "../../assets/images/scheme-plane7.svg"


// Изобрадение шапки модального окна с самолетом
import backgroundPlane1 from "../../assets/images/gulfstream_g550.jpg"
import backgroundPlane2 from "../../assets/images/gulfstream_g650.jpg"
import backgroundPlane3 from "../../assets/images/legacy_600.jpg"
import backgroundPlane4 from "../../assets/images/legacy_650.jpg"
import backgroundPlane5 from "../../assets/images/challenger_850.jpg"
import backgroundPlane6 from "../../assets/images/hawker_800xp.jpg"
import backgroundPlane7 from "../../assets/images/hawker_850xp.jpg"


export const cardProperties = [
    {
        id: 0,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Gulfstream G550',
        image: plane1,
        own: true,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 12',
            en: 'Seats: 12'
        },
        // bed: {
        //     ru: 'Спальных мест: 12',
        //     en: 'Sleeping accommodations: 12'
        // },
        luggage: {
            ru:'Багажный отдел: 4.81 м³',
            en:'Luggage space: 4.81 m³'
        },
        mainParams: {
            ru: [
                ['Год выпуска: ', '2003'],
                ['Крейсерская скорость (км/ч): ', '941'],
                ['Дальность полета (км): ', '12 501'],
                ['Количество мест: ', '12 (10 + диван)'],
                ['Оптимальная загрузка: ', '8-10'],
                // ['Спальные места: ', '5'],
                ['Объем багажного отделения (м³): ', '4,81'],
            ],
            en: [
                ['Year of manufacture: ', '2003'],
                ['Cruising speed (km/h): ', '941'],
                ['Range (km): ', '12 501'],
                ['Seating capacity: ', '12 (10 + sofa)'],
                ['Optimal load: ', '8-10'],
                // ['Sleeping accommodations: ', '5'],
                ['Cargo compartment volume (m³): ', '4,81']
            ]
        },
        notes: {
            ru: [
                ['Курение: ', 'не допускается'],
                ['Электронные сигареты, вейпы и аналоги: ', 'не допускается'],
                ['Домашние питомцы: ', 'разрешено'],
                ['Wi-Fi (спутниковый): ', 'есть'],
            ],
            en: [
                ['Smoking: ', 'not allowed'],
                ['E-cigarettes, vapes, and similar devices: ', 'not allowed'],
                ['Pets: ', 'allowed'],
                ['Wi-Fi (satellite): ', 'available']
            ]
        },
        modalImage: backgroundPlane1,
        scheme: schemePlane1
    },
    {
        id: 1,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Gulfstream G650',
        image: plane2,
        own: true,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 12',
            en: 'Seats: 12'
        },
        // bed: {
        //     ru: 'Спальных мест: 12',
        //     en: 'Sleeping accommodations: 12'
        // },
        luggage: {
            ru:'Багажный отдел: 5,52 м³',
            en:'Luggage space: 5,52 m³'
        },
        mainParams: {
            ru: [
                ['Год выпуска: ', '2016'],
                ['Крейсерская скорость (км/ч): ', '982'],
                ['Дальность полета (км): ', '12 964'],
                ['Количество мест: ', '12 (10 + 2 дивана)'],
                ['Оптимальная загрузка: ', '8-10'],
                // ['Спальные места: ', '12'],
                ['Объем багажного отделения (м³): ', '5,52']
            ],
            en: [
                ['Year of manufacture: ', '2016'],
                ['Cruising speed (km/h): ', '982'],
                ['Range (km): ', '12 964'],
                ['Seating capacity: ', '12 (10 + 2 sofa)'],
                ['Optimal load: ', '8-10'],
                // ['Sleeping accommodations: ', '12'],
                ['Cargo compartment volume (m³): ', '5,52']
            ]
        },
        notes: {
            ru: [
                ['Курение: ', 'не допускается'],
                ['Электронные сигареты, вейпы и аналоги: ', 'не допускается'],
                ['Домашние питомцы: ', 'разрешено'],
                ['Wi-Fi (спутниковый): ', 'есть'],
            ],
            en: [
                ['Smoking: ', 'not allowed'],
                ['E-cigarettes, vapes, and similar devices: ', 'не допускается'],
                ['Pets: ', 'allowed'],
                ['Wi-Fi (satellite): ', 'available']
            ]
        },
        modalImage: backgroundPlane2,
        scheme: schemePlane2
    },
    {
        id: 2,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Hawker 850XP',
        image: plane3,
        own: false,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 8',
            en: 'Seats: 8'
        },
        // bed: {
        //     ru: 'Спальных мест: 8',
        //     en: 'Sleeping accommodations: 8'
        // },
        luggage: {
            ru:'Багажный отдел: 1.5 м³',
            en:'Luggage space: 1.5 m³'
        },
        mainParams: {
            ru: [
                // ['Год выпуска: ', '2003'],
                ['Крейсерская скорость (км/ч): ', '793'],
                ['Дальность полета (км): ', '4893'],
                ['Количество мест: ', '8'],
                ['Оптимальная загрузка: ', '7'],
                // ['Спальные места: ', '8'],
                ['Объем багажного отделения (м³): ', '1,5'],
            ],
            en: [
                // ['Year of manufacture: ', '2003'],
                ['Cruising speed (km/h): ', '793'],
                ['Range (km): ', '4893'],
                ['Seating capacity: ', '8'],
                ['Optimal load: ', '7'],
                // ['Sleeping accommodations: ', '8'],
                ['Cargo compartment volume (m³): ', '1,5']
            ]
        },
        notes: {},
        modalImage: backgroundPlane7,
        scheme: schemePlane7
    },
    {
        id: 3,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Hawker 800XP',
        image: plane4,
        own: false,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 8',
            en: 'Seats: 8'
        },
        // bed: {
        //     ru: 'Спальных мест: 8',
        //     en: 'Sleeping accommodations: 8'
        // },
        luggage: {
            ru:'Багажный отдел: 1.4 м³',
            en:'Luggage space: 1.4 m³'
        },
        mainParams: {
            ru: [
                // ['Год выпуска: ', '2003'],
                ['Крейсерская скорость (км/ч): ', '741'],
                ['Дальность полета (км): ', '4704'],
                ['Количество мест: ', '8'],
                ['Оптимальная загрузка: ', '6'],
                // ['Спальные места: ', '8'],
                ['Объем багажного отделения (м³): ', '1,4'],
            ],
            en: [
                // ['Year of manufacture: ', '2003'],
                ['Cruising speed (km/h): ', '741'],
                ['Range (km): ', '4704'],
                ['Seating capacity: ', '8'],
                ['Optimal load: ', '6'],
                // ['Sleeping accommodations: ', '8'],
                ['Cargo compartment volume (m³): ', '1,4']
            ]
        },
        notes: {},
        modalImage: backgroundPlane6,
        scheme: schemePlane6
    },
    {
        id: 4,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Legacy 600',
        image: plane5,
        own: false,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 13',
            en: 'Seats: 13'
        },
        // bed: {
        //     ru: 'Спальных мест: 13',
        //     en: 'Sleeping accommodations: 13'
        // },
        luggage: {
            ru:'Багажный отдел: 6.8 м³',
            en:'Luggage space: 6.8 m³'
        },
        mainParams: {
            ru: [
                // ['Год выпуска: ', '2003'],
                ['Крейсерская скорость (км/ч): ', '834'],
                ['Дальность полета (км): ', '6019'],
                ['Количество мест: ', '13'],
                ['Оптимальная загрузка: ', '10'],
                // ['Спальные места: ', '13'],
                ['Объем багажного отделения (м³): ', '6,8'],
            ],
            en: [
                // ['Year of manufacture: ', '2003'],
                ['Cruising speed (km/h): ', '834'],
                ['Range (km): ', '6019'],
                ['Seating capacity: ', '13'],
                ['Optimal load: ', '10'],
                // ['Sleeping accommodations: ', '13'],
                ['Cargo compartment volume (m³): ', '6,8']
            ]
        },
        notes: {},
        modalImage: backgroundPlane3,
        scheme: schemePlane3
    },
    {
        id: 5,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Legacy 650',
        image: plane6,
        own: false,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 13',
            en: 'Seats: 13'
        },
        // bed: {
        //     ru: 'Спальных мест: 13',
        //     en: 'Sleeping accommodations: 13'
        // },
        luggage: {
            ru:'Багажный отдел: 6.8 м³',
            en:'Luggage space: 6.8 m³'
        },
        mainParams: {
            ru: [
                // ['Год выпуска: ', '2003'],
                ['Крейсерская скорость (км/ч): ', '850'],
                ['Дальность полета (км): ', '7200'],
                ['Количество мест: ', '13'],
                ['Оптимальная загрузка: ', '11'],
                // ['Спальные места: ', '13'],
                ['Объем багажного отделения (м³): ', '6,8'],
            ],
            en: [
                // ['Year of manufacture: ', '2003'],
                ['Cruising speed (km/h): ', '850'],
                ['Range (km): ', '7200'],
                ['Seating capacity: ', '13'],
                ['Optimal load: ', '11'],
                // ['Sleeping accommodations: ', '13'],
                ['Cargo compartment volume (m³): ', '6,8']
            ]
        },
        notes: {},
        modalImage: backgroundPlane4,
        scheme: schemePlane4
    },
    {
        id: 6,
        planeSize: {
            ru: 'Средний самолет',
            en: 'Medium aircraft'
        },
        planeTitle: 'Challenger 850',
        image: plane7,
        own: false,
        ownPlaceholder: {
            ru: 'Собственный самолет',
            en: 'Our own aircraft'
        },
        partnerPlaceholder: {
            ru: 'Партнерская компания',
            en: 'Affiliate company'
        },
        place: {
            ru: 'Мест: 16',
            en: 'Seats: 16'
        },
        // bed: {
        //     ru: 'Спальных мест: 8-10',
        //     en: 'Sleeping accommodations: 8-10'
        // },
        luggage: {
            ru:'Багажный отдел: 5.5 м³',
            en:'Luggage space: 5.5 m³'
        },
        mainParams: {
            ru: [
                // ['Год выпуска: ', '2003'],
                ['Крейсерская скорость (км/ч): ', '819'],
                ['Дальность полета (км): ', '5206'],
                ['Количество мест: ', '16'],
                ['Оптимальная загрузка: ', '12'],
                // ['Спальные места: ', '5'],
                ['Объем багажного отделения (м³): ', '5,5'],
            ],
            en: [
                // ['Year of manufacture: ', '2003'],
                ['Cruising speed (km/h): ', '819'],
                ['Range (km): ', '5206'],
                ['Seating capacity: ', '16'],
                ['Optimal load: ', '12'],
                // ['Sleeping accommodations: ', '5'],
                ['Cargo compartment volume (m³): ', '5,5']
            ]
        },
        notes: { },
        modalImage: backgroundPlane5,
        scheme: schemePlane5
    },
]
