import React, {useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss'

import place from "../../assets/icons/place.png"
import luggage from "../../assets/icons/luggage.png"
import PlaneModal from "../modals/planeModal/PlaneModal";
import {cardProperties} from "./parkData"

const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                speed: 500,
                dots: false,
                arrows: false
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
                speed: 500
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                speed: 500,
                dots: true,
                arrows: false,
            },
            margin: "0px",
        }
    ]
};

const Park = ({ lang }) => {
    const [showPlaneModal, setShowPlaneModal] = useState(false);
    const [selectedPlane, setSelectedPlane] = useState({})
    const sliderRef = useRef(null);
    const isSelectENLang = lang === 'en'
    const [state, setState] = useState({startX: null, startY: null})
    const next = () => {
        sliderRef.current.slickNext();
    };

    const previous = () => {
        sliderRef.current.slickPrev();
    };
    const handleMouseDown = (event) => {
        setState({
            startX: event.clientX,
            startY: event.clientY,
        });
    };
    const openPlaneModal = (event, cardId) => {
        const { startX, startY } = state
        const currentX = event.clientX
        const currentY = event.clientY
        if (startX && startY) {
            const deltaX = Math.abs(currentX - startX)
            const deltaY = Math.abs(currentY - startY)
            if (deltaX > 10 || deltaY > 10) {
                return
            }
        }
        setSelectedPlane(cardProperties[cardId])
        setShowPlaneModal(true)
    }
    return (
        <div className="park container">
            <div className="park-head">
                <div className="park-head-arrow prev-arrow hidden__mobile" onClick={previous}>
                    <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.57 5.93001L3.5 12L9.57 18.07" stroke="#C49D64" />
                        <path d="M62.3594 12.0868L3.67163 12.0868" stroke="#C49D64" />
                    </svg>
                </div>
                <div className="park-head-text">
                    <h2>{ isSelectENLang ? 'Our own' : 'Собственный' }<br className="hidden__desktop" /> { isSelectENLang ? 'fleet of aircraft' : 'парк самолетов' }</h2>
                    <p>{ isSelectENLang ? 'as well as over 7000 aircraft from our partners - at your service' : 'а также более 7000 самолетов наших партнеров – к Вашим услугам' }</p>
                </div>
                <div className="park-head-arrow next-arrow hidden__mobile" onClick={next}>
                    <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M54.43 18.07L60.5 12L54.43 5.92999" stroke="#C49D64" />
                        <path d="M1.64058 11.9132L60.3284 11.9132" stroke="#C49D64" />
                    </svg>
                </div>
            </div>
            <Slider ref={sliderRef} {...settings} className="slider">
                {cardProperties.map(card => (
                    <div className="park-card" key={card.id} onClick={(event) => openPlaneModal(event, card.id)} onMouseDown={(event) => handleMouseDown(event)} >
                        <p>{card.planeSize[lang]}</p>
                        <h4>{card.planeTitle}</h4>
                        <img className="park-card-image" src={card.image} alt="plane"/>
                        <div className={`park-card-own ${card.own ? 'yes' : 'no'}`}>
                            {card.own ? card.ownPlaceholder[lang] : card.partnerPlaceholder[lang]}
                        </div>
                        <div className="park-card-description">
                            <div className="park-card-description__item">
                                <img src={place} alt="nn"/>
                                <p>{card.place[lang]}</p>
                            </div>
                            <div className="park-card-description__item">
                                <img src={luggage} alt="nn"/>
                                <p>{card.luggage[lang]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            {showPlaneModal && (
                <PlaneModal isOpen={showPlaneModal} onClose={() => setShowPlaneModal(false)} planeData={selectedPlane} lang={lang}/>
            )}
        </div>
    );
};

export default Park;
