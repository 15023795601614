import React from 'react';
import {GoogleMap, InfoBox, LoadScript, Marker, InfoWindow} from "@react-google-maps/api";
import {mapOptions} from "../map/mapOptions";
import Constants from "../../constants/Constants";
import './style.scss'

import marker from '../../../src/assets/images/contactsMarker.png';
import mapPin from '../../../src/assets/icons/map-pin.png'
import phone from '../../../src/assets/icons/phone.png'
import telegram from '../../../src/assets/icons/telegram.png'
import whatsapp from '../../../src/assets/icons/whatsapp.png'

const center = {
    lat: 42.882493,
    lng: 74.610280
};

const Contacts = ({ lang }) => {
    const containerStyle = {
        width: '100%',
        height: '100%'
    };
    const isSelectEnLang = lang === 'en'
    return (
        <div className="contacts container">
            <div className="contacts-info">
                <h2>
                    { isSelectEnLang ? 'Contacts' : 'Контакты' }
                </h2>
                <div className="contacts-info-items">
                    <div className="contacts-info-items__item">
                        <img src={mapPin} alt="marker"/>
                        <p>
                            <span>{ isSelectEnLang ? '720040, Kyrgyzstan,' : '720040, Кыргызстан,' }</span><br/>
                            { isSelectEnLang ? 'Bishkek, Zhumabek Street, 123' : 'г. Бишкек, ул. Жумабека, 123' }
                        </p>
                    </div>
                    <div className="contacts-info-items__item">
                        <img src={phone} alt="phone"/>
                        <a href="tel:+996500000268">
                            +996 500 000 268
                        </a>
                    </div>
                    <div className="contacts-info-items__item">
                        <img src={telegram} alt="telegram"/>
                        <a href="https://t.me/Asman_assistant" target="_blank">
                            @Asman_assistant
                        </a>
                    </div>
                    <div className="contacts-info-items__item">
                        <img src={whatsapp} alt="whatsapp"/>
                        <a href="https://wa.me/+996500000268">
                            +996 500 000 268
                        </a>
                    </div>
                </div>
            </div>
            <div className="contacts-map">
                <LoadScript googleMapsApiKey={Constants.API_KEY}>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={16.7}
                        options={mapOptions}
                    >
                        <Marker
                            position={center}
                            icon={{
                                url: marker,
                                anchor: { x: 9, y: 12 }
                            }}
                        />
                        <InfoBox
                            position={{ lat: 42.883593, lng: 74.609932 }}
                            options={{ closeBoxURL: "", enableEventPropagation: true }}
                            style={{width: "100%", height: "100%", top: "0", right: "0"}}
                        >
                            <div className="info">
                                <div className="info-title">
                                    { isSelectEnLang ? 'Kyrgyzstan, Bishkek' : 'Кыргызстан, Бишкек' }
                                </div>
                                <div className="info-text">
                                    { isSelectEnLang ? 'Zhumabek Street, 123 720040' : 'ул. Жумабека, 123 720040' }
                                </div>
                            </div>
                        </InfoBox>
                    </GoogleMap>
                </LoadScript>
            </div>
        </div>
    );
};

export default Contacts;
