import React from 'react'
import Header from '../../components/header/Header'
import Button from '../../components/buttons/main-button/MainButton'
import './index.scss'
import {Link} from "react-router-dom";

const NotFound = () => {
    const lang = localStorage.getItem('lang')
    return (
        <div className="App-error ">
            <Header classname="error-header" lang={lang}/>
            <div className="error-title">
                <div className="error-title-wrapper container">
                    <div className="error">
                        404
                    </div>
                    <div className="description">
                        {lang === 'en' ? 'Something went wrong.' : 'Что-то пошло не так.'}<br/>
                        {lang === 'en' ? 'We are already looking for a solution to the problem!': 'Мы уже ищем решение проблемы!'}
                    </div>
                    <Link to={lang === 'en' ? '/en' : '/'}>
                        <Button onClick="goTo">{lang === 'en' ? 'Go back to the main page' : 'Вернуться на главную'}</Button>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default NotFound
