import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";
// import moment from "moment";

const DateSelect = ({
  label,
  updateDateData,
  dateValid,
  selectedDateData,
  isSelectENLang,
  firstDateData,
  index,
  updateFirstDateData
}) => {
  const [expanded, setExpanded] = useState(false);
  const [date, setDate] = useState("");
  const [openedPicker, setOpenedPicker] = useState(false);
  const inputRef = useRef(null);

  const handleExpansion = () => {
    setExpanded(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setFocus();
        inputRef.current.input.setAttribute("readonly", "readonly");
      }
    });
  };

  const handleInput = (date, e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
      setOpenedPicker(false);
    }

    setDate(date);
    updateDateData(date);
    if (index === 0) {
      updateFirstDateData(date);
    }
  };

  const handleBlur = (event) => {
    if (!date) {
      setExpanded(false);
    }
    setOpenedPicker(false);
  };

  const handleFocus = () => {
    setOpenedPicker(true);
  };

  // Форматирование названий дней недели
  const formatWeekDay = (nameOfWeekDay) => {
    return String(nameOfWeekDay)[0];
  };

  useEffect(() => {
    if (selectedDateData) {
      const addDays = 2;
      const date = new Date(selectedDateData);
      date.setDate(selectedDateData.getDate() + addDays);
      setDate(date);
      updateDateData(date);
      setExpanded(true);
    }
  }, []);

  return (
    <div
      className={`date-selector ${expanded ? "expanded" : ""}`}
      onClick={handleExpansion}
    >
      <div className="date-selector-label">
        {expanded ? (
          <label>
            <div className="date-selector-label__placeholder">
              <span>{label}</span>
              <svg
                className={`${openedPicker ? "opened" : ""}`}
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9997 1.17C10.8123 0.983753 10.5589 0.879211 10.2947 0.879211C10.0305 0.879211 9.77707 0.983753 9.5897 1.17L5.9997 4.71L2.4597 1.17C2.27234 0.983753 2.01889 0.879211 1.7547 0.879211C1.49052 0.879211 1.23707 0.983753 1.0497 1.17C0.955976 1.26297 0.881582 1.37357 0.830813 1.49543C0.780044 1.61729 0.753906 1.74799 0.753906 1.88C0.753906 2.01202 0.780044 2.14272 0.830813 2.26458C0.881582 2.38644 0.955976 2.49704 1.0497 2.59L5.2897 6.83C5.38267 6.92373 5.49327 6.99813 5.61513 7.04889C5.73699 7.09966 5.86769 7.1258 5.9997 7.1258C6.13172 7.1258 6.26242 7.09966 6.38428 7.04889C6.50614 6.99813 6.61674 6.92373 6.7097 6.83L10.9997 2.59C11.0934 2.49704 11.1678 2.38644 11.2186 2.26458C11.2694 2.14272 11.2955 2.01202 11.2955 1.88C11.2955 1.74799 11.2694 1.61729 11.2186 1.49543C11.1678 1.37357 11.0934 1.26297 10.9997 1.17Z"
                  fill="#C39D64"
                />
              </svg>
            </div>
            <svg
              className={classNames(
                "mobileArrow",
                !openedPicker && "mobileArrow_selected"
              )}
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9997 1.17C10.8123 0.983753 10.5589 0.879211 10.2947 0.879211C10.0305 0.879211 9.77707 0.983753 9.5897 1.17L5.9997 4.71L2.4597 1.17C2.27234 0.983753 2.01889 0.879211 1.7547 0.879211C1.49052 0.879211 1.23707 0.983753 1.0497 1.17C0.955976 1.26297 0.881582 1.37357 0.830813 1.49543C0.780044 1.61729 0.753906 1.74799 0.753906 1.88C0.753906 2.01202 0.780044 2.14272 0.830813 2.26458C0.881582 2.38644 0.955976 2.49704 1.0497 2.59L5.2897 6.83C5.38267 6.92373 5.49327 6.99813 5.61513 7.04889C5.73699 7.09966 5.86769 7.1258 5.9997 7.1258C6.13172 7.1258 6.26242 7.09966 6.38428 7.04889C6.50614 6.99813 6.61674 6.92373 6.7097 6.83L10.9997 2.59C11.0934 2.49704 11.1678 2.38644 11.2186 2.26458C11.2694 2.14272 11.2955 2.01202 11.2955 1.88C11.2955 1.74799 11.2694 1.61729 11.2186 1.49543C11.1678 1.37357 11.0934 1.26297 10.9997 1.17Z"
                fill="#C39D64"
              />
            </svg>
            <DatePicker
              selected={date}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={(date, e) => handleInput(date, e)} //only when value has changed
              dateFormat="dd.MM.yyyy"
              minDate={index === 0 ? new Date() : new Date(firstDateData)}
              formatWeekDay={formatWeekDay}
              ref={inputRef}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              readonly
            />
          </label>
        ) : (
          <div
            className={`date-selector-placeholder ${
              !dateValid && label === "Дата"
                ? "no-valid"
                : !dateValid && label === "Date"
                ? "no-valid-en"
                : "valid"
            }`}
          >
            <span>{label}</span>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9997 1.17C10.8123 0.983753 10.5589 0.879211 10.2947 0.879211C10.0305 0.879211 9.77707 0.983753 9.5897 1.17L5.9997 4.71L2.4597 1.17C2.27234 0.983753 2.01889 0.879211 1.7547 0.879211C1.49052 0.879211 1.23707 0.983753 1.0497 1.17C0.955976 1.26297 0.881582 1.37357 0.830813 1.49543C0.780044 1.61729 0.753906 1.74799 0.753906 1.88C0.753906 2.01202 0.780044 2.14272 0.830813 2.26458C0.881582 2.38644 0.955976 2.49704 1.0497 2.59L5.2897 6.83C5.38267 6.92373 5.49327 6.99813 5.61513 7.04889C5.73699 7.09966 5.86769 7.1258 5.9997 7.1258C6.13172 7.1258 6.26242 7.09966 6.38428 7.04889C6.50614 6.99813 6.61674 6.92373 6.7097 6.83L10.9997 2.59C11.0934 2.49704 11.1678 2.38644 11.2186 2.26458C11.2694 2.14272 11.2955 2.01202 11.2955 1.88C11.2955 1.74799 11.2694 1.61729 11.2186 1.49543C11.1678 1.37357 11.0934 1.26297 10.9997 1.17Z"
                fill="#C39D64"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateSelect;
