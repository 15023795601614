import React from 'react';
import MapForm from "../mapForm/MapForm";

import './style.scss';




const Title = ({ lang }) => {
    const isSelectENLang = lang === 'en'
    return (
        <div className="title">
            <div className="title-wrapper container">
                <h1>
                    { isSelectENLang ? 'Charter flights to any airport in the world' : 'Чартерные рейсы в любой аэропорт мира' }
                </h1>
                <div className="description">
                    { isSelectENLang ? 'Provide flight details. We\'ll take care of the rest.' : 'Укажите информацию о перелете. Об остальном позаботимся мы.' }
                </div>
                <MapForm lang={lang}/>
            </div>
        </div>
    );
};

export default Title;
