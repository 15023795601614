import React, { useState } from 'react';

import './style.scss'
import Accordion from "../accordion/Accordion";

const Questions = ({items, lang}) => {
        const [activeIndex, setActiveIndex] = useState(null);

        const handleToggle = (index) => {
            setActiveIndex(activeIndex === index ? null : index);
        };
        const isSelectENLang = lang === 'en'
    return (
        <div className="questions container">
            <h2>{ isSelectENLang ? 'Frequently asked questions from our clients' : 'Частые вопросы от наших клиентов' }</h2>
            <div className="questions-background"></div>
            {items.map((item, index) => (
                <Accordion
                    key={index}
                    title={item.title[lang]}
                    isOpen={activeIndex === index}
                    onToggle={() => handleToggle(index)}
                    clas={index}
                >
                    {item.content[lang]}
                </Accordion>
            ))}
        </div>
    );
};

export default Questions;
