import './en.css'
import React from 'react'
// import { Helmet } from "react-helmet"
import Header from "../../components/header/Header"
import Title from "../../components/title/Title"
import Service from "../../components/service/Service"
import Park from "../../components/park/Park"
import Request from "../../components/request/Request"
import AboutUs from "../../components/aboutUs/AboutUs"
import Questions from "../../components/questions/Questions"
import questions from "../../static/faq.json"
import Contacts from "../../components/contacts/Contacts"
import Footer from "../../components/footer/Footer"

const EngPage = () => {
    localStorage.setItem('lang', 'en')
    // const pageTitle = 'Asman Executive'
    // const pageDescription = 'Charter flights in Kyrgyzstan. We will deliver you to any airport in the world'
    return (
        <div className="App">
            {/*<Helmet>*/}
            {/*    <title>{pageTitle}</title>*/}
            {/*    <meta name="description" content={pageDescription} />*/}
            {/*    <meta property="og:description" content={pageDescription} />*/}
            {/*</Helmet>*/}
            <Header lang="en" />
            <Title lang="en" />
            <Service lang="en" />
            <Park id="park" lang="en" />
            <Request lang="en" />
            <AboutUs id="about" lang="en" />
            <Questions items={questions} lang="en"/>
            <Contacts id="contacts" lang="en" />
            <Footer lang="en" />
        </div>
    )
}

export default EngPage
