import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import './index.css';
import RuPage from "./pages/index";
import EngPage from "./pages/en";
import reportWebVitals from './reportWebVitals';
import NotFound from "./pages/404";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index path="/" element={ <RuPage /> } />
                <Route path="en" element={ <EngPage /> } />
                <Route path="/404" element={<NotFound /> } />
                <Route path="*" element={ <Navigate to="/404" replace /> } />
            </Routes>
        </BrowserRouter>
    )
}

ReactDOM.createRoot(document.getElementById("root")).render(<App />)

export default App

reportWebVitals();
