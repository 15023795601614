import React from 'react';
import MainButton from "../buttons/main-button/MainButton";
import {Link} from "react-scroll";

import './style.scss';
import window from "../../assets/images/window.png";

const Request = ({ lang }) => {
    const isSelectENLang = lang === 'en'
    return (
        <div className="request container">
            <div className="request-content">
                <h2 className="request-content-title">
                    { isSelectENLang ? 'Just leave information about your flight' : 'Просто оставьте информацию о Вашем перелете' }
                </h2>
                <div className="request-content-text">
                    { isSelectENLang ? 'We will take care of all other tasks.We will provide complete information about your flight within 3-6 hours from the time of submitting your request.' : 'Все остальные задачи решим мы. Мы предоставим полную информацию о Вашем перелете через 3-6 часов с момента подачи заявки.' }
                </div>
                <Link to="title" smooth={true} duration={500}>
                    <MainButton>{ isSelectENLang ? 'Submit your request' : 'Оставить заявку' }</MainButton>
                </Link>
                <p className="request-content-prompt">
                    { isSelectENLang ? '* The processing time of your request depends on the time zone of our partner airlines' : '* Скорость обработки Вашей заявки зависит от часового пояса, в котором находятся партнерские авиакомпании' }
                </p>
            </div>
            <img className="request-image" src={window} alt="director"/>
        </div>
    );
};

export default Request;
