import React, {useState} from 'react';

import './style.scss'



const Comment = (props) => {
    const [comment, setComment] = useState('')
    const isSelectEnLang = props.lang === 'en'
    const handleChange = (event) => {
        setComment(event.target.value)
        props.onValueChange(event.target.value)
    }
    const handleInputText = (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + 'px';
    }
    const placeholderComment = isSelectEnLang ? 'Your preferences' : 'Ваши пожелания'
    return (
        <label className="comment">
            <span>{ isSelectEnLang ? 'Comment' : 'Комментарий' }</span>
            <textarea name="" id="" cols="30" rows="1" placeholder={placeholderComment} onInput={(event) => handleInputText(event)} value={comment} onChange={handleChange}/>
        </label>
    );
};

export default Comment;
