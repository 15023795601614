import './index.css'
import React from 'react'
// import { Helmet } from "react-helmet"
import Header from "../../components/header/Header"
import Title from "../../components/title/Title"
import Service from "../../components/service/Service"
import Park from "../../components/park/Park"
import Request from "../../components/request/Request"
import AboutUs from "../../components/aboutUs/AboutUs"
import Questions from "../../components/questions/Questions"
import questions from "../../static/faq.json"
import Contacts from "../../components/contacts/Contacts"
import Footer from "../../components/footer/Footer"

const RuPage = () => {
    localStorage.setItem('lang', 'ru')
    // const pageTitle = 'Asman Executive'
    // const pageDescription = 'Чартерные рейсы в Кыргызстане. Доставим в любой аэропорт мира'
    return (
        <div className="App">
            {/*<Helmet>*/}
            {/*    <title>{pageTitle}</title>*/}
            {/*    <meta name="description" content={pageDescription}/>*/}
            {/*    <meta property="og:description" content={pageDescription}/>*/}
            {/*</Helmet>*/}
            <Header lang="ru" />
            <Title lang="ru" />
            <Service lang="ru" />
            <Park id="park" lang="ru" />
            <Request lang="ru" />
            <AboutUs id="about" lang="ru" />
            <Questions items={questions} lang="ru"/>
            <Contacts id="contacts" lang="ru" />
            <Footer lang="ru" />
        </div>
    )
}

export default RuPage
