import React from 'react';
import './style.scss'

const MainButton = ({onPress, children}) => {
    return (
        <button className="button" onClick={onPress}>
            {children}
        </button>
    );
};

export default MainButton;
