import React from 'react';

import './style.scss';
import owner from '../../assets/images/Owner.png';
import signature from '../../assets/images/Signature.png';

const AboutUs = ({ lang }) => {
    const isSelectEnLang = lang === "en"
    return (
        <div className="about container">
            <img className="about-image" src={owner} alt="director"/>
            <div className="about-content">
                <h2 className="about-content-title">
                    { isSelectEnLang ? 'About Us' : 'О компании' }
                </h2>
                <div className="about-content-text">
                    { isSelectEnLang
                        ? 'Asman Consult is a company founded in Kyrgyzstan, serving clients in Central Asia and worldwide. Our team consists of industry professionals with experience in leading EU companies in the business aviation field. Our top-notch service is a combination of passion for our work and the highest level of expertise. That\'s why 90% of our clients become loyal customers'
                        : 'Компания Asman Consult основана в Кыргызстане и работает с клиентами как в Средней Азии, так и по всему миру. Наша команда - профессионалы отрасли с опытом работы в ведущих компаниях ЕС в области деловой авиации. Наш топовый сервис - это сочетание любви к своему делу с высочайшим уровнем компетенций. Вот почему 90% клиентов нашей компании становятся постоянными.' }

                </div>
                <img className="about-content-signature" src={signature} alt="signature"/>
                <p className="about-content-name">
                    { isSelectEnLang ? 'Zholdoshbekov Tolomush' : 'Жолдошбеков Толомуш' }
                </p>
                <p className="about-content-director">
                    { isSelectEnLang ? 'Director of Asman Consult LLC' : 'Директор Asman Consult LLC' }
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
