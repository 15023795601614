import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./style.scss";
import Constants from "../../constants/Constants";
import MainButton from "../buttons/main-button/MainButton";
import moment from "moment";

const FeedBackModal = ({ isOpen, onClose, dataForSend, lang }) => {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validName, setValidName] = useState(true);
  const [validTel, setValidTel] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const isSelectENLang = lang === "en";

  const placeholderName = isSelectENLang ? "Name" : "Имя";
  const placeholderTel = isSelectENLang ? "Phone" : "Телефон";
  const placeholderEmail = isSelectENLang ? "E-mail" : "Почта";

  useEffect(() => {
    const body = document.querySelector("body");
    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpen]);

  const isValidTel = (val) => {
    return val.length >= 10;
  };
  const isValidName = (val) => {
    return val.length >= 2;
  };
  const isValidEmail = (val) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(val);
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const filteredValue = inputValue.replace(/[^0-9+]/g, "");
    setTel(filteredValue);
    setValidTel(true)
  };
  const handleClick = async () => {
    if (isValidName(name) && isValidTel(tel) && isValidEmail(email)) {
      const routes = [];
      for (let i = 0; i < dataForSend.cities.length; i++) {
        if (
          dataForSend.cities[i][0].length &&
          dataForSend.cities[i][1].length
        ) {
          routes.push({
            from: dataForSend.cities[i][0],
            to: dataForSend.cities[i][1],
            date: moment(dataForSend.dates[i][0]).format("DD-MM-YYYY"),
            time: dataForSend.times[i][0],
            persons: dataForSend.persons[i][0],
          });
        }
      }
      let data = {
        name,
        email,
        phone: tel,
        type: dataForSend.type,
        comment: dataForSend.comment ?? null,
        routes,
      };
      try {
        setLoading(true);
        await fetch(`${Constants.URL_BASE}/send`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              setRequestSuccess(true);
              setTimeout(() => {
                onClose();
                setRequestSuccess(false);
              }, 2000);
            } else {
              alert(data.message);
            }
          });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else {
      if (!isValidName(name)) {
        setValidName(false);
      } else {
        setValidName(true);
      }
      if (!isValidTel(tel)) {
        setValidTel(false);
      } else {
        setValidTel(true);
      }
      if (!isValidEmail(email)) {
        setValidEmail(false);
      } else {
        setValidEmail(true);
      }
    }
  };
  const handleInputName = (event) => {
    const value = event.target.value;
    setName(value);
    setValidName(true)
  };
  const handleInputEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    setValidEmail(true)
  };
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="feedback modal-overlay">
      <div className="feedback-wrapper">
        <button className="feedback-close" onClick={onClose}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4099 12L19.7099 5.71C19.8982 5.5217 20.004 5.2663 20.004 5C20.004 4.7337 19.8982 4.47831 19.7099 4.29C19.5216 4.1017 19.2662 3.99591 18.9999 3.99591C18.7336 3.99591 18.4782 4.1017 18.2899 4.29L11.9999 10.59L5.70994 4.29C5.52164 4.1017 5.26624 3.99591 4.99994 3.99591C4.73364 3.99591 4.47824 4.1017 4.28994 4.29C4.10164 4.47831 3.99585 4.7337 3.99585 5C3.99585 5.2663 4.10164 5.5217 4.28994 5.71L10.5899 12L4.28994 18.29C4.19621 18.383 4.12182 18.4936 4.07105 18.6154C4.02028 18.7373 3.99414 18.868 3.99414 19C3.99414 19.132 4.02028 19.2627 4.07105 19.3846C4.12182 19.5064 4.19621 19.617 4.28994 19.71C4.3829 19.8037 4.4935 19.8781 4.61536 19.9289C4.73722 19.9797 4.86793 20.0058 4.99994 20.0058C5.13195 20.0058 5.26266 19.9797 5.38452 19.9289C5.50638 19.8781 5.61698 19.8037 5.70994 19.71L11.9999 13.41L18.2899 19.71C18.3829 19.8037 18.4935 19.8781 18.6154 19.9289C18.7372 19.9797 18.8679 20.0058 18.9999 20.0058C19.132 20.0058 19.2627 19.9797 19.3845 19.9289C19.5064 19.8781 19.617 19.8037 19.7099 19.71C19.8037 19.617 19.8781 19.5064 19.9288 19.3846C19.9796 19.2627 20.0057 19.132 20.0057 19C20.0057 18.868 19.9796 18.7373 19.9288 18.6154C19.8781 18.4936 19.8037 18.383 19.7099 18.29L13.4099 12Z"
              fill="#111111"
            />
          </svg>
        </button>
        <div className="modal">
          {loading && !requestSuccess && (
            <div className="modal-wrapper">
              <svg
                viewBox="0 0 38 38"
                xmlns="http://www.w3.org/2000/svg"
                width="128"
                height="128"
                stroke="#C29146"
              >
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth="2">
                    <circle strokeOpacity=".25" cx="18" cy="18" r="18"></circle>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.8s"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </path>
                  </g>
                </g>
              </svg>
            </div>
          )}

          {!loading && !requestSuccess && (
            <div>
              <h2>
                {isSelectENLang
                  ? "We will contact you!"
                  : "Мы свяжемся с Вами!"}
              </h2>
              <form>
                <input
                  className={`${validName ? "valid" : "no-valid"}`}
                  type="text"
                  placeholder={placeholderName}
                  onInput={(event) => handleInputName(event)}
                />
                <input
                  value={tel}
                  className={`${validTel ? "valid" : "no-valid"}`}
                  maxLength="15"
                  type="tel"
                  placeholder={placeholderTel}
                  onChange={(event) => handleInputChange(event)}
                />
                <input
                  className={`${validEmail ? "valid" : "no-valid"}`}
                  type="text"
                  placeholder={placeholderEmail}
                  onInput={(event) => handleInputEmail(event)}
                />
              </form>
              <MainButton onPress={() => handleClick()}>
                {isSelectENLang ? "Send" : "Отправить"}
              </MainButton>
              <p>
                {isSelectENLang
                  ? 'We guarantee complete confidentiality of your data. By clicking the "Send" button, you agree to our'
                  : "Мы гарантируем полную конфиденциальность Ваших данных. Нажимая кнопку отправить, Вы соглашаетесь с "}
                <a href={isSelectENLang ? "/Privacy_policy_eng_Asman_Consult_LLC.pdf" : "/Политика_конфиденциальности_ru_Asman_Consult_LLC.pdf"} target="_blank">
                  {isSelectENLang
                    ? " Privacy Policy"
                    : "Политикой конфиденциальности"}
                </a>
                .
              </p>
            </div>
          )}

          {requestSuccess && (
            <div className="modal-wrapper">
              <h2>
                {isSelectENLang
                  ? "Request sent successfully!"
                  : "Запрос успешно отправлен!"}
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default FeedBackModal;
