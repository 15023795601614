import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Constants from "../../../constants/Constants";
import "./style.scss";

const CitySelect = ({
  label,
  updateFromData,
  updateDateFromForSend,
  updateDateToForSend,
  updateToData,
  direction,
  fromValid,
  toValid,
  selectedFromData,
  selectedToData,
  fromData,
  toData,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [city, setCity] = useState("");
  const [backspaceKeyDown, setBackspaceKeyDown] = useState(false);
  const inputRef = useRef(null);
  const currentLang = window.location.pathname.replace(/\//g, "");

  const handleExpansion = () => {
    setExpanded(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  };

  const handleBlur = (event) => {
    if (!city) {
      setExpanded(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setBackspaceKeyDown(true);
    } else {
      setBackspaceKeyDown(false);
    }
  };

  let cities = [];
  const [suggestedCities, setSuggestedCities] = useState([]);
  const handleInput = async (event) => {
    const value = event.target.value;
    cities = [];
    setCity(value);
    if (value.trim() === "" || (value.length > 3 && backspaceKeyDown)) {
      setSuggestedCities([]);
      if (direction === "from") {
        updateFromData("");
        updateDateFromForSend("");
      }
      if (direction === "to") {
        updateToData("");
        updateDateToForSend("");
      }
      return;
    }
    const data = {
      lang: currentLang.length > 0 ? currentLang : "ru",
      term: value,
    };
    try {
      await fetch(`${Constants.URL_BASE}/airports`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => cities.push(...data));
    } catch (e) {
      console.error(e);
    }
    const filteredCities = cities;
    setSuggestedCities(filteredCities);
  };

  const handleCitySelect = (selectedCity) => {
    if (
      (selectedCity?.coords?.lat === fromData?.lat &&
        selectedCity?.coords?.lng === fromData?.lng) ||
      (selectedCity?.coords?.lat === toData?.lat &&
        selectedCity?.coords?.lng === toData?.lng)
    ) {
      return;
    }

    setCity(selectedCity.name);
    setSuggestedCities([]);
    setExpanded(false);
    if (inputRef.current) {
      inputRef.current.blur();
    }

    if (direction === "from") {
      updateFromData(selectedCity.coords, selectedCity.name);
      updateDateFromForSend(selectedCity.name);
    }
    if (direction === "to") {
      updateToData(selectedCity.coords, selectedCity.name);
      updateDateToForSend(selectedCity.name);
    }
  };

  useEffect(() => {
    if (selectedFromData) {
      handleCitySelect(selectedFromData);
      setExpanded(true);
    }
    if (selectedToData) {
      handleCitySelect(selectedToData);
      setExpanded(true);
    }
  }, []);

  return (
    <div
      className={`city-selector ${expanded ? "expanded" : ""}`}
      onClick={handleExpansion}
    >
      <div className="city-selector-label">
        {expanded ? (
          <label>
            <div className="city-selector-label__placeholder">
              <span>{label}</span>
              <svg
                className={`${suggestedCities.length > 0 ? "opened" : ""}`}
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9997 1.17C10.8123 0.983753 10.5589 0.879211 10.2947 0.879211C10.0305 0.879211 9.77707 0.983753 9.5897 1.17L5.9997 4.71L2.4597 1.17C2.27234 0.983753 2.01889 0.879211 1.7547 0.879211C1.49052 0.879211 1.23707 0.983753 1.0497 1.17C0.955976 1.26297 0.881582 1.37357 0.830813 1.49543C0.780044 1.61729 0.753906 1.74799 0.753906 1.88C0.753906 2.01202 0.780044 2.14272 0.830813 2.26458C0.881582 2.38644 0.955976 2.49704 1.0497 2.59L5.2897 6.83C5.38267 6.92373 5.49327 6.99813 5.61513 7.04889C5.73699 7.09966 5.86769 7.1258 5.9997 7.1258C6.13172 7.1258 6.26242 7.09966 6.38428 7.04889C6.50614 6.99813 6.61674 6.92373 6.7097 6.83L10.9997 2.59C11.0934 2.49704 11.1678 2.38644 11.2186 2.26458C11.2694 2.14272 11.2955 2.01202 11.2955 1.88C11.2955 1.74799 11.2694 1.61729 11.2186 1.49543C11.1678 1.37357 11.0934 1.26297 10.9997 1.17Z"
                  fill="#C49D64"
                />
              </svg>
            </div>
            <input
              type="text"
              placeholder=""
              value={city}
              onChange={handleInput}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              autoFocus
            />
            <svg
              className={classNames(
                "mobileArrow",
                suggestedCities.length === 0 && "mobileArrow_selected"
              )}
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9997 1.17C10.8123 0.983753 10.5589 0.879211 10.2947 0.879211C10.0305 0.879211 9.77707 0.983753 9.5897 1.17L5.9997 4.71L2.4597 1.17C2.27234 0.983753 2.01889 0.879211 1.7547 0.879211C1.49052 0.879211 1.23707 0.983753 1.0497 1.17C0.955976 1.26297 0.881582 1.37357 0.830813 1.49543C0.780044 1.61729 0.753906 1.74799 0.753906 1.88C0.753906 2.01202 0.780044 2.14272 0.830813 2.26458C0.881582 2.38644 0.955976 2.49704 1.0497 2.59L5.2897 6.83C5.38267 6.92373 5.49327 6.99813 5.61513 7.04889C5.73699 7.09966 5.86769 7.1258 5.9997 7.1258C6.13172 7.1258 6.26242 7.09966 6.38428 7.04889C6.50614 6.99813 6.61674 6.92373 6.7097 6.83L10.9997 2.59C11.0934 2.49704 11.1678 2.38644 11.2186 2.26458C11.2694 2.14272 11.2955 2.01202 11.2955 1.88C11.2955 1.74799 11.2694 1.61729 11.2186 1.49543C11.1678 1.37357 11.0934 1.26297 10.9997 1.17Z"
                fill="#C39D64"
              />
            </svg>
            {suggestedCities.length > 0 && (
              <div className="city-selector-suggestions">
                {suggestedCities.map((city, index) => (
                  <div
                    className={classNames(
                      "city-selector-suggestions__item",
                      (city.coords.lat === fromData?.lat &&
                        city.coords.lng === fromData?.lng) ||
                        (city.coords.lat === toData?.lat &&
                          city.coords.lng === toData?.lng)
                        ? "city-selector-suggestions__item_disabled"
                        : ""
                    )}
                    key={index}
                    onClick={() => handleCitySelect(city)}
                  >
                    {city.name}
                  </div>
                ))}
              </div>
            )}
          </label>
        ) : (
          <div
            className={`city-selector-placeholder ${
              (!fromValid && direction === "from" && label === "Откуда") ||
              (!toValid && direction === "to" && label === "Куда")
                ? "no-valid"
                : (!fromValid &&
                    direction === "from" &&
                    label === "Departure point") ||
                  (!toValid && direction === "to" && label === "Destination")
                ? "no-valid-en"
                : ""
            }`}
          >
            <span>{label}</span>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9997 1.17C10.8123 0.983753 10.5589 0.879211 10.2947 0.879211C10.0305 0.879211 9.77707 0.983753 9.5897 1.17L5.9997 4.71L2.4597 1.17C2.27234 0.983753 2.01889 0.879211 1.7547 0.879211C1.49052 0.879211 1.23707 0.983753 1.0497 1.17C0.955976 1.26297 0.881582 1.37357 0.830813 1.49543C0.780044 1.61729 0.753906 1.74799 0.753906 1.88C0.753906 2.01202 0.780044 2.14272 0.830813 2.26458C0.881582 2.38644 0.955976 2.49704 1.0497 2.59L5.2897 6.83C5.38267 6.92373 5.49327 6.99813 5.61513 7.04889C5.73699 7.09966 5.86769 7.1258 5.9997 7.1258C6.13172 7.1258 6.26242 7.09966 6.38428 7.04889C6.50614 6.99813 6.61674 6.92373 6.7097 6.83L10.9997 2.59C11.0934 2.49704 11.1678 2.38644 11.2186 2.26458C11.2694 2.14272 11.2955 2.01202 11.2955 1.88C11.2955 1.74799 11.2694 1.61729 11.2186 1.49543C11.1678 1.37357 11.0934 1.26297 10.9997 1.17Z"
                fill="#C39D64"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default CitySelect;
