import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.scss";
import plane1 from "../../../assets/images/PLANE-light.png";
import plane2 from "../../../assets/images/PLANE-middle.png";
import plane3 from "../../../assets/images/PLANE-hard.png";
import plane4 from "../../../assets/images/PLANE-extrahard.png";

const cardProperties = [
  {
    id: 1,
    planeSize: {
      ru: "Легкие самолеты",
      en: "Light aircraft",
    },
    planePlaces: {
      ru: "1-8 пассажиров",
      en: "1-8 passengers",
    },
    image: plane1,
    min: 1,
    max: 8,
  },
  {
    id: 2,
    planeSize: {
      ru: "Средние самолеты",
      en: "Medium aircraft",
    },
    planePlaces: {
      ru: "6-10 пассажиров",
      en: "6-10 passengers",
    },
    image: plane2,
    min: 6,
    max: 10,
  },
  {
    id: 3,
    planeSize: {
      ru: "Тяжелые самолеты",
      en: "Heavy aircraft",
    },
    planePlaces: {
      ru: "10-12 пассажиров",
      en: "10-12 passengers",
    },
    image: plane3,
    min: 10,
    max: 12,
  },
  {
    id: 4,
    planeSize: {
      ru: "Авиалайнеры",
      en: "Airliners",
    },
    planePlaces: {
      ru: "от 19 пассажиров",
      en: "from 19 passengers",
    },
    image: plane4,
    min: 19,
    max: 130,
  },
];

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        dots: true,
        arrows: false,
      },
      margin: "0px",
    },
  ],
};

const PlanesTypes = ({ count, lang, type }) => {
  const [activeCardId, setActiveCardId] = useState(1);
  const sliderRef = useRef(null);

  const handlerTypeAirplane = (id) => {
    const airplainType =
      cardProperties.find((card) => card.id === id)?.planeSize[lang] ?? null;
    type(airplainType);
    setActiveCardId(id);
  };

  useEffect(() => {
    const optimalPlane = cardProperties.find(
      (e) => e.min <= count.value && e.max >= count.value
    );
    const id = optimalPlane?.id || 4;
    setActiveCardId(id);
    type(optimalPlane?.planeSize[lang]);
  }, [count]);

  return (
    <div className="planes">
      <div className="planes-wrapper hidden__mobile">
        {cardProperties.map((card) => (
          <div
            className={`planes-card ${
              card.id === activeCardId ? "active" : ""
            }`}
            key={card.id}
            onClick={() => handlerTypeAirplane(card.id)}
          >
            <h5>{card.planeSize[lang]}</h5>
            <p>{card.planePlaces[lang]}</p>
            <img className="planes-card-image" src={card.image} alt="plane" />
          </div>
        ))}
      </div>

      <Slider ref={sliderRef} {...settings} className="slider hidden__desktop">
        {cardProperties.map((card) => (
          <div
            className={`planes-card ${
              card.id === activeCardId ? "active" : ""
            }`}
            key={card.id}
            onClick={() => setActiveCardId(card.id)}
          >
            <div
              className={`planes-card-wrapper ${
                card.id === activeCardId ? "active" : ""
              }`}
            >
              <h5>{card.planeSize[lang]}</h5>
              <p>{card.planePlaces[lang]}</p>
              <img className="planes-card-image" src={card.image} alt="plane" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PlanesTypes;
