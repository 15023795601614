import React from 'react';

import './style.scss'
import logo from "../../assets/images/logo.svg";
import MainButton from "../buttons/main-button/MainButton";
import {Link} from "react-scroll";

const Footer = ({ lang }) => {
    const isSelectENLang = lang === 'en'
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-top">
                    <Link to="header" className="footer-top-logo" smooth={true} duration={500}>
                        <img src={logo} alt='logo' />
                    </Link>
                    <nav className="footer-top-nav">
                        <Link to="park" smooth={true} duration={500}>
                            { isSelectENLang ? 'Aircraft fleet' : 'Флот' }
                        </Link>
                        <Link to="about" smooth={true} duration={500}>
                            { isSelectENLang ? 'About us' : 'О компании' }
                        </Link>
                        <Link to="contacts" smooth={true} duration={500}>
                            { isSelectENLang ? 'Contacts' : 'Контакты' }
                        </Link>
                    </nav>
                    <Link to="title" smooth={true} duration={500}>
                        <MainButton>{ isSelectENLang ? 'Submit your request' : 'Оставить заявку' }</MainButton>
                    </Link>
                </div>
                <div className="footer-bottom">
                    <a>© Asman Consult LLC, 2023</a>
                    <a href={ isSelectENLang ? "/Privacy_policy_eng_Asman_Consult_LLC.pdf" : "/Политика_конфиденциальности_ru_Asman_Consult_LLC.pdf" } target="_blank" >{ isSelectENLang ? 'Privacy Policy' : 'Политика конфиденциальности' }</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
